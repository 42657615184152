import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import Page from '../components/Page'

const Content = ({ transition, data }) => {
  return (
    <Layout>
      {data && (
        <Page
          transition={transition}
          bg={data.datoCmsPage.treeParent.background.url}
          align={data.datoCmsPage.treeParent.alignment}
          hotspots={data.datoCmsPage.treeParent.treeChildren}
          hotspotLabel={true}
          logoColor={data.datoCmsPage.treeParent.logoColor}
          icon={data.datoCmsPage.treeParent.icon}
          backLink={
            data.datoCmsPage.treeParent ? data.datoCmsPage.treeParent.slug : '/'
          }
          backLinkX={data.datoCmsPage.treeParent.backLinkX}
          backLinkY={data.datoCmsPage.treeParent.backLinkY}
          lvl={1}
          menuData={{ sub: true, ...data.menuData }}
          currentSlug={data.datoCmsPage.treeParent.treeParent.slug}
          showContent={true}
          contentTitle={data.datoCmsPage.displayTitle || data.datoCmsPage.title}
          contentBody={data.datoCmsPage.body.md.html || ''}
          contentAttachments={
            data.datoCmsPage.attachments ? data.datoCmsPage.attachments : []
          }
          contentAttachmentIcons={data.datoCmsPage.attachmentIcons}
          backIcon={
            data.datoCmsPage.backIcon ? data.datoCmsPage.backIcon.url : ''
          }
          backSlug={
            data.datoCmsPage.treeParent ? data.datoCmsPage.treeParent.slug : '/'
          }
          backButton={true}
          animation={false}
        />
      )}
    </Layout>
  )

  return null
}
//const Content = ({ transition, data }) => {
//  console.log('CONTENT', data)
//  return (
//    <Layout>
//      {data && (
//        <ContentPage
//          transition={transition}
//          bg={
//            data.datoCmsPage.background ? data.datoCmsPage.background.url : ''
//          }
//          title={data.datoCmsPage.displayTitle || data.datoCmsPage.title}
//          body={data.datoCmsPage.body.md.html || ''}
//          menuData={{ sub: true, ...data.menuData }}
//          backIcon={
//            data.datoCmsPage.treeParent &&
//            data.datoCmsPage.treeParent.treeParent.backIcon
//              ? data.datoCmsPage.treeParent.treeParent.backIcon.url
//              : ''
//          }
//          backSlug={
//            data.datoCmsPage.treeParent ? data.datoCmsPage.treeParent.slug : '/'
//          }
//          attachments={
//            data.datoCmsPage.attachments ? data.datoCmsPage.attachments : []
//          }
//          attachmentIcons={data.datoCmsPage.attachmentIcons}
//          currentSlug={data.datoCmsPage.treeParent.treeParent.slug}
//        />
//      )}
//    </Layout>
//  )
//
//  return null
//}

export default Content

export const query = graphql`
  query ContentQuery($slug: String) {
    datoCmsPage(slug: { eq: $slug }) {
      title
      displayTitle
      body: bodyNode {
        md: childMarkdownRemark {
          html
        }
      }
      attachmentIcons
      attachments {
        __typename

        ... on DatoCmsPdf {
          title
          filename
          file {
            url
          }
          thumbnail {
            url
            width
            height
          }
          icon {
            url
            width
            height
          }
        }
        ... on DatoCmsVideo {
          title
          videoUrl
          thumbnail {
            url
            width
            height
          }
          icon {
            url
            width
            height
          }
        }
      }
      icon {
        url
      }
      backIcon {
        url
      }
      backLink {
        slug
      }
      backLinkX
      backLinkY
      background {
        url
      }
      alignment
      logoColor

      treeParent {
        slug
        position

        icon {
          url
        }
        menuIcon {
          url
        }
        backLink {
          slug
        }
        background {
          url
        }
        alignment

        treeParent {
          title
          displayTitle
          slug
          position
          menuIcon {
            url
          }
          backIcon {
            url
          }
          attachments {
            __typename
            ... on DatoCmsVideo {
              title
              videoUrl
              thumbnail {
                url
                width
                height
              }
            }
          }
          treeChildren {
            title
            displayTitle
            treeChildren {
              title
              displayTitle
              slug
            }
          }
        }
        treeChildren {
          title
          displayTitle
          hotspotX
          hotspotY
          slug
          icon {
            url
          }
        }
      }
      treeChildren {
        title
        displayTitle
        hotspotX
        hotspotY
        slug
        icon {
          url
        }
      }
    }
    menuData: datoCmsPage(root: { eq: true }) {
      treeChildren {
        displayTitle
        slug
        menuIcon {
          url
        }
        treeChildren {
          displayTitle
          slug
          treeChildren {
            displayTitle
            slug
          }
        }
      }
    }
  }
`
